.container-innerhtml{
    margin: 2rem 2.5rem;
}

@media (max-width: 700px) {
    .container-innerhtml{
        margin: 0.5rem 0;
    }
  }

  @media (max-width: 900px) {
    .container-shared-content{
        margin: -2rem 0 2rem 0px;
      }
  }

 