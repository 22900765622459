.carousel-indicators li {
    height: 13px !important;
    border-radius: 50% !important;
    width: 13px !important;
}
.carousel-indicators .active {
    background-color: var(--color-secondary-menium) !important;
}

.picsum-img-wrapper-banner .image-banner {
    width: 100%;
    // height: 600px;
    aspect-ratio: 15 / 6.3;
    object-fit: var(--object-fit);
}

@media (max-width: 1160px) {
    .carousel-caption {
        visibility: hidden;
    }

    .picsum-img-wrapper-banner .image-banner {
        width: 100%;
        // height: 500px;
        object-fit: var(--object-fit);
    }
}

@media (max-width: 800px) {
    .picsum-img-wrapper-banner .image-banner {
        width: 100%;
        //height: 400px;
        object-fit: var(--object-fit);
    }
}

@media (max-width: 500px) {
    .picsum-img-wrapper-banner .image-banner {
        width: 100%;
        //height: 300px;
        object-fit: var(--object-fit);
    }
}

@media (max-width: 400px) {
    .picsum-img-wrapper-banner .image-banner {
        width: 100%;
        //height: 200px;
        object-fit: var(--object-fit);
    }
}
