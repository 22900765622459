.content-restricted {
    display: grid;
    grid-template-columns: 110px calc(100% - 110px);
    grid-template-rows: auto;
    grid-gap: 10px;
}

@media (max-width: 550px) {
    .content-restricted {
        grid-template-columns: 100%;
    }
}
