.container-access {
    margin: 2rem 1rem;
}
.card-access {
    background-color: var(--color-tertiary-gray);
    min-height: 250px;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 1rem;
    position: relative;
    cursor: pointer;
    &_description {
        width: 85%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        h3 {
            color: var(--color-secondary-menium);
            font-family: "lato-bold" !important;
            margin-bottom: -5px;
            font-size: 1.2rem;
            transition: all 0.3s linear;
        }

        p {
            color: var(--color-secondary-light);
            line-height: 22px;
            font-size: 1rem;
            margin-top: 10px;
            padding-right: 40px;
        }
    }
    &_icon {
        width: 40%;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
            width: 80px;
            height: 80px;
            fill: var(--color-secondary-menium);
            transition: all 0.3s linear;
        }
    }
    &_button {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 40px;
        border-radius: 5px;
        margin-left: 5px;
        opacity: 0;
        position: absolute;
        bottom: 10px;
        right: 0;
        transition: all 0.3s linear;
        .button-icon {
            border: none;
            background-color: transparent;
            svg {
                width: 100px;
                height: 40px;
            }
        }
    }
    &:hover {
        .card-access_button {
            opacity: 1;
        }
    }
}

@media (max-width: 1250px) {
    .card-access {
        flex-direction: column;
        &_description {
            p {
                padding: 0;
            }
        }
    }
    .card-access_icon {
        width: 90%;
        margin: 2rem 0 1rem 0;
    }
}

@media (max-width: 950px) {
    .card-access_icon {
        width: 90%;
        margin: 2rem 2rem;
    }
    .container-access {
        margin: 1rem 1rem 0 1rem !important;
        p {
            padding-right: 0px;
        }
        .card-access_button {
            padding-right: 1rem !important;
            opacity: 1;
        }
    }

    .container-discover {
        margin: 1rem 1rem 0 1rem !important;
    }
    .card-access_description {
        padding-bottom: 25px;
    }
}
