.container-description-representation {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 5rem 15rem 1rem 15rem;
    &_icon {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        svg {
            fill: var(--color-primary-dark);
            width: 100px;
            height: 100px;
        }
        span {
            text-align: center;
            color: var(--color-secondary-menium);
            font-size: 1.2rem;
            font-weight: 500;
            margin-top: 0.5rem;
            line-height: 22px;
        }
    }

    &_description {
        p {
            padding: 1rem 1rem 1rem 3rem;
            line-height: 22px;
            color: var(--color-secondary-light);
        }
    }
}

@media (max-width: 1200px) {
    .container-description-representation {
        margin: 5rem 5rem 1rem 5rem;
    }
}

@media (max-width: 820px) {
    .container-description-representation {
        margin: 5rem 1rem 1rem 1rem;
    }
}

@media (max-width: 700px) {
    .container-description-representation {
        flex-direction: column;
        &_icon {
            width: 100%;
        }
        &_description {
            p {
                padding: 1rem;
            }
        }
    }
}

// cards representations
.subtitle-representations {
    text-align: center;
    margin: 0;
    color: var(--color-primary-dark-shade);
    font-weight: 500;
    font-size: 1.3rem;
    margin: -10px 1rem 1rem 1rem;
}

.container-card-representations {
    .col-card {
        &_title {
            text-align: center;
            font-size: 1.2rem;
            margin: 1rem 2rem;
            line-height: 22px;
            &--blue {
                color: var(--color-secondary-menium);
            }
            &--golden {
                color: var(--color-primary-dark-shade);
            }
        }
    }
    margin: 3rem 1rem;
    .container-row {
        display: grid;
        grid-template-columns: 33% 33% 33%;
    }

    .container-row-only-one {
        display: grid;
        grid-template-columns: 100%;
    }

    .container-row-only-two {
        display: grid;
        grid-template-columns: 50% 50%;
    }
    .row-card {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        margin: 1.5rem 0.5rem;

        img {
            width: 250px;
            height: 250px;
            aspect-ratio: 5/5;
            object-fit: cover;
            border-radius: var(--radius-img);
        }
        span {
            color: var(--color-secondary-light);
            margin: 0.5rem 1rem;
            font-size: 1rem;
            text-align: center;
        }
        .subtile {
            color: var(--color-secondary-menium);
            margin: -0.5rem 1rem 0.5rem 1rem;
            font-size: 1rem;
            font-weight: 500;
        }
        .description {
            color: var(--color-secondary-menium);
            margin: -0.5rem 1rem 0.5rem 1rem;
            font-size: 1rem;
            font-weight: 600;
            text-align: center;
        }
        svg {
            cursor: pointer;
            fill: var(--color-secondary-menium);
            width: 20px;
            height: 20px;
            transition: all 0.3s linear;
            &:hover {
                fill: var(--color-primary-dark);
            }
        }
    }
}

@media (max-width: 800px) {
    .container-row {
        grid-template-columns: 50% 50% !important;
    }
    .container-row-only-one {
        grid-template-columns: 100% !important;
    }
}

@media (max-width: 550px) {
    .container-row {
        grid-template-columns: 100% !important;
    }
}
