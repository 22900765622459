.container-card-publications {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 2.5rem 2.5rem;
    &_image {
        width: 50%;
        height: 280px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: var(--radius-img);
        }
    }

    &_description {
        min-height: 290px;
        width: 50%;
        margin: 0 0 1rem 1rem;
        padding-bottom: 2rem;
        position: relative;
        h3 {
            color: var(--color-secondary-menium);
            font-family: "lato-bold" !important;
            font-size: 1.3rem;
            width: calc(100% - 110px);
        }
        .subtitle {
            color: var(--color-secondary-menium);
            margin-bottom: 0;
            font-size: 1rem;
        }
        .text-date {
            color: var(--color-secondary-menium);
            margin-bottom: 0;
            font-size: 1.1rem;
            position: absolute;
            right: 0;
            top: -15px;
        }
        p {
            color: var(--color-secondary-light);
            line-height: 22px;
            text-align: justify;
        }
        .container-cards_button {
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }
}

.container-rich-text-event {
    margin: 2.5rem 2.5rem;
}

@media (max-width: 800px) {
    .container-card-publications {
        flex-direction: column;
        margin: 2rem 1rem;
        &_image {
            width: 100%;
        }

        &_description {
            width: 100%;
            margin: 1rem 0 0 0;
            .text-date {
                top: 0;
            }
            .container-cards_button {
                bottom: -10;
            }
        }
    }

    .container-rich-text-event {
        margin: 2rem 1rem;
    }
    .container-card-publications_description {
        min-height: auto;
    }

    .p-scrollpanel {
        height: 100% !important;
    }
}

@media (max-width: 600px) {
    .container-card-publications_image {
        aspect-ratio: 10/4;
        height: auto;
    }
}
