.container-filters {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    mat-form-field {
        margin-right: 10px;
    }
}


@media (max-width: 680px) {
    .container-filters {
        flex-direction: column;
        form {
            display: flex;
            flex-direction: column;
        }
    }
}
