.content-file-preview {
    flex-direction: column;
}
.container-file-preview {
    width: 70%;
    padding: 1rem;
    box-shadow: 0 2px 6px rgba(33, 33, 38, 0.2);
    border-radius: 5px;
    .row-file {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 5px;
        &_title {
            color: var(--color-secondary-menium);
            width: calc(100% - 140px);
            display: flex;
            justify-content: flex-start;
            align-items: center;
            text-align: start;
            font-family: "lato-italic";
            margin-right: 0.5rem;
            font-size: 1rem;
            line-height: 18px;
        }
        .container-button {
            width: 50%;
        }
        &_button {
            border: none;
            background-color: transparent;
            width: 50%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-left: 0.5rem;
            svg {
                width: 40px;
                height: 40px;
                fill: var(--color-primary-dark-shade);
            }
        }
    }
}
.button-preview {
    display: flex;
    justify-content: flex-end !important;
    align-items: center !important;
    width: 50%;
    button {
        padding: 5px 20px 5px 20px;
    }
}

.content-uploadDownload {
    margin-top: 1rem;
    width: 70%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .container-cards_button {
        justify-content: flex-end !important;
        button {
            background-color: var(--color-primary-dark-shade);
            color: var(--color-contrast);
            border: none;
            margin-right: 0px;
            padding: 5px 20px 5px 20px;
        }
        .button-blue {
            background-color: transparent;
            color: var(--color-secondary-menium);
            border: var(--border-btn) solid var(--color-secondary-menium);
            padding: 5px 20px 5px 20px;
            &:hover {
                color: var(--color-contrast);
                background-color: var(--color-secondary-menium);
                border: var(--border-btn) solid var(--color-secondary-menium);
            }
        }
        .text-file {
            color: var(--color-secondary-menium);
            margin-right: 10px;
        }
    }
}

.icon-arrow-down {
    width: 10px;
    height: 10px;
    position: absolute;
    right: 5px;
    fill: var(--color-secondary-menium);
    top: 7px;
}

.filter-files {
    flex-direction: column;
    h3 {
        margin: 2rem 0 0 0;
        color: var(--color-secondary-menium);
        font-size: 1.2rem;
    }
}

@media (max-width: 1097px) {
    .container-file-preview {
        width: 98%;
    }

    .content-uploadDownload {
        width: 98%;
    }
}

@media (max-width: 1024px) {
    .container-file-preview {
        width: 70%;
    }

    .content-uploadDownload {
        width: 70%;
    }
}
@media (max-width: 776px) {
    .container-file-preview {
        width: 98%;
    }

    .content-uploadDownload {
        width: 98%;
    }
}

@media (max-width: 576px) {
    .content-uploadDownload {
        flex-direction: column;
        width: 100%;
        align-items: end;
        .button-file {
            margin-bottom: 1rem;
        }
    }
}
