.skeleton-animation {
  background: var(--skeleton-grad);
  background-size: 1200px 100px;
  border-radius: 5px;
  animation: skeleton 1s forwards linear infinite;
}

.skeleton-animation-white {
  background: var(--skeleton-grad-white);
  background-size: 1200px 100px;
  border-radius: 5px;
  animation: skeleton 1s forwards linear infinite;
}

@keyframes skeleton {
  0% {
    background-position: -600px 0;
  }
  100% {
    background-position: 600px 0;
  }
}

// animation  fade

/* For this animation we need to set up a keyframe animation to define how our element fades in. We then just call it onto the element we want to use it on */
.fadeIn {
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
  -moz-animation: fadeIn ease 0.5s;
  -o-animation: fadeIn ease 0.5s;
  -ms-animation: fadeIn ease 0.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/** fadeInRight **/

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translatex(10px);
    -moz-transform: translatex(10px);
    -o-transform: translatex(10px);
    transform: translatex(10px);
  }
  to {
    opacity: 1;
    -webkit-transform: translatex(0);
    -moz-transform: translatex(0);
    -o-transform: translatex(0);
    transform: translatex(0);
  }
}
@-moz-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translatex(10px);
    -moz-transform: translatex(10px);
    -o-transform: translatex(10px);
    transform: translatex(10px);
  }
  to {
    opacity: 1;
    -webkit-transform: translatex(0);
    -moz-transform: translatex(0);
    -o-transform: translatex(0);
    transform: translatex(0);
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translatex(100px);
    -moz-transform: translatex(100px);
    -o-transform: translatex(100px);
    transform: translatex(100px);
  }
  to {
    opacity: 1;
    -webkit-transform: translatex(0);
    -moz-transform: translatex(0);
    -o-transform: translatex(0);
    transform: translatex(0);
  }
}
.in-right {
  -webkit-animation-name: fadeInRight;
  -moz-animation-name: fadeInRight;
  -o-animation-name: fadeInRight;
  animation-name: fadeInRight;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.3s;
  -moz-animation-duration: 0.3s;
  -o-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  -o-animation-duration: 0.3s;
  animation-delay: 0.3s;
}

.effectFadeInDown {
  animation: fadeInDown 0.5s linear 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-25px);
    -ms-transform: translateY(-25px);
    transform: translateY(-25px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

/** fadeInLeft **/

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translatex(-10px);
    -moz-transform: translatex(-10px);
    -o-transform: translatex(-10px);
    transform: translatex(-10px);
  }
  to {
    opacity: 1;
    -webkit-transform: translatex(0);
    -moz-transform: translatex(0);
    -o-transform: translatex(0);
    transform: translatex(0);
  }
}
@-moz-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translatex(-10px);
    -moz-transform: translatex(-10px);
    -o-transform: translatex(-10px);
    transform: translatex(-10px);
  }
  to {
    opacity: 1;
    -webkit-transform: translatex(0);
    -moz-transform: translatex(0);
    -o-transform: translatex(0);
    transform: translatex(0);
  }
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translatex(-100px);
    -moz-transform: translatex(-100px);
    -o-transform: translatex(-100px);
    transform: translatex(-100px);
  }
  to {
    opacity: 1;
    -webkit-transform: translatex(0);
    -moz-transform: translatex(0);
    -o-transform: translatex(0);
    transform: translatex(0);
  }
}
.in-left {
  -webkit-animation-name: fadeInLeft;
  -moz-animation-name: fadeInLeft;
  -o-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.4s;
  -moz-animation-duration: 0.4s;
  -o-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  -o-animation-duration: 0.4s;
  animation-delay: 0.4s;
}

/** fadeInRight **/

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translatex(10px);
    -moz-transform: translatex(10px);
    -o-transform: translatex(10px);
    transform: translatex(10px);
  }
  to {
    opacity: 1;
    -webkit-transform: translatex(0);
    -moz-transform: translatex(0);
    -o-transform: translatex(0);
    transform: translatex(0);
  }
}
@-moz-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translatex(10px);
    -moz-transform: translatex(10px);
    -o-transform: translatex(10px);
    transform: translatex(10px);
  }
  to {
    opacity: 1;
    -webkit-transform: translatex(0);
    -moz-transform: translatex(0);
    -o-transform: translatex(0);
    transform: translatex(0);
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translatex(100px);
    -moz-transform: translatex(100px);
    -o-transform: translatex(100px);
    transform: translatex(100px);
  }
  to {
    opacity: 1;
    -webkit-transform: translatex(0);
    -moz-transform: translatex(0);
    -o-transform: translatex(0);
    transform: translatex(0);
  }
}
.in-right {
  -webkit-animation-name: fadeInRight;
  -moz-animation-name: fadeInRight;
  -o-animation-name: fadeInRight;
  animation-name: fadeInRight;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.3s;
  -moz-animation-duration: 0.3s;
  -o-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  -o-animation-duration: 0.3s;
  animation-delay: 0.3s;
}

.loaders-skeleton,
.loaders-skeleton:before,
.loaders-skeleton:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  animation-fill-mode: both;
  animation: bblFadInOut 1.8s infinite ease-in-out;
}
.loaders-skeleton {
  color: var(--color-secondary-dark);
  font-size: 6px;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;
  margin-top: -15px;
}
.loaders-skeleton:before,
.loaders-skeleton:after {
  content: "";
  position: absolute;
  top: 0;
}
.loaders-skeleton:before {
  left: -3.5em;
  animation-delay: -0.32s;
}
.loaders-skeleton:after {
  left: 3.5em;
}

@keyframes bblFadInOut {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
