//info
.container-signature-info,
.container-signature-obtaining,
.container-signature-renewal {
    h3 {
        font-size: 1.2rem;
        color: var(--color-secondary-menium);
    }
    p {
        color: var(--color-tertiary-dark-shade);
    }
    ol li {
        color: var(--color-tertiary-dark-shade);
    }
    a {
        color: var(--color-secondary-menium);
        transition: all 0.3s linear;
        &:hover {
            color: var(--color-primary-dark-shade);
            text-decoration: none;
        }
    }
}

.container-form-signature {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    grid-gap: 10px;
    .container-cards_button {
        background-color: var(--color-secondary-menium);
        color: var(--color-contrast);
        &:hover {
            background-color: var(--color-contrast);
            color: var(--color-secondary-menium);
            border: 1px solid var(--color-secondary-menium);
        }
    }
}

@media (max-width: 625px) {
    .container-form-signature {
        grid-template-columns: 100%;
    }
}
