.mat-form-field-wrapper {
  padding-bottom: 0;
  font-family: "lato", sans-serif;
}
.mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin: 0 !important;
  font-family: "lato", sans-serif;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 5px 0 10px 0;
  font-family: "lato", sans-serif;
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float
  .mat-input-server:focus
  + .mat-form-field-label-wrapper
  .mat-form-field-label {
  transform: translateY(-18px) scale(0.75);
  font-family: "lato", sans-serif;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  margin-top: -10px;
  font-family: "lato", sans-serif;
}
mat-form-field {
  height: 35px;
  margin-bottom: 1rem;
  font-size: 0.9rem !important;
  font-family: "lato", sans-serif;
}
mat-error {
  margin-top: 0.9rem;
  font-family: "lato", sans-serif;
  line-height: 10px;
}

.container {
  .mat-form-field-appearance-outline .mat-form-field-label {
    margin-top: -8.5px !important;
  }
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: var(--color-secondary-menium) !important;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: var(--color-secondary-menium) !important;
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: var(--color-secondary-menium) !important;
}

.mat-ripple:not(:empty) {
  transform: none !important;
}

.mat-radio-checked .mat-radio-inner-circle {
  border-radius: 5px;
  transform: scale(1) !important;
}

.mat-radio-outer-circle {
  background-color: white;
  border-radius: 5px !important;
  height: 17px !important;
  width: 17px !important;
  border: none;
  border-style: none !important;
}
.mat-radio-inner-circle {
  height: 17px !important;
  width: 17px !important;
}

.mat-radio-label-content {
  color: var(--color-contrast);
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-radius: 5px;
  background-color: var(--color-contrast);
  border: none;
}
mat-radio-button {
  .mat-ripple-element {
    display: none !important;
  }
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: var(--color-primary-dark-shade);
}

.mat-radio-outer-circle {
  border-color: var(--color-secondary-menium);
}

.mat-radio-button {
  font-family: "lato" !important;
}

.radio-chips {
  .mat-radio-checked .mat-radio-inner-circle {
    border-radius: 5px;
    transform: scale(1) !important;
  }
  .mat-radio-outer-circle {
    background-color: var(--color-tertiary-light);
    border-radius: 5px !important;
    height: 17px !important;
    width: 17px !important;
    border: none;
    border-style: none !important;
  }
  .mat-radio-inner-circle {
    height: 17px !important;
    width: 17px !important;
  }
  .mat-radio-label-content {
    color: var(--color-secondary-menium);
  }
  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-radius: 5px;
    background-color: var(--color-tertiary-light);
    border: none;
  }
  mat-radio-button {
    .mat-ripple-element {
      display: none !important;
    }
  }
  .mat-radio-button.mat-accent .mat-radio-inner-circle,
  .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
  .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: var(--color-primary-dark-shade);
  }

  .mat-radio-outer-circle {
    border-color: var(--color-secondary-menium);
  }

  .mat-radio-button {
    font-family: "lato" !important;
    margin-right: 20px;
  }
}

.pointer-input-date {
  &:hover {
    cursor: pointer !important;
  }
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: var(--color-primary-dark) !important;
}

mat-checkbox {
  .mat-ripple-element {
    display: none !important;
  }
}

//toggle
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--color-primary-dark-shade);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: var(--color-primary-light);
}

mat-slide-toggle {
  .mat-ripple-element {
    display: none !important;
  }
}

.error-input {
  transition: all 0.3s linear;
  border: 1px solid #f54e42 !important;
}
.mat-form-field-label-wrapper {
  top: -10px !important;
}

// stepper
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: var(--color-secondary-dark-shade);
}

.mat-step-icon {
  height: 30px !important;
  width: 30px !important;
}

.mat-step-header .mat-step-label.mat-step-label-active {
  color: var(--color-secondary-dark-shade) !important;
  font-size: 1rem;
}
