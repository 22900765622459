.carrucel-discover {
    .carousel-indicators {
        bottom: -13px;
        li {
            height: 12px !important;
            border-radius: 50% !important;
            width: 12px !important;
            background-color: var(--color-tertiary-dark-shade);
        }
    }
    .carousel-indicators .active {
        background-color: var(--color-secondary-menium) !important;
    }

    .picsum-img-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        .imgen-discover {
            width: 370px;
            height: 245px;
            aspect-ratio: 4/3;
            object-fit: var(--object-fit);
            padding: 2.5rem;
            border-radius: var(--radius-img);
        }
        .discover-description {
            width: 50%;
            margin: 2rem 1rem 2rem 0;
            height: 100%;
            h3 {
                color: var(--color-secondary-menium);
                margin-bottom: -5px;
                font-size: 1.2rem;
                margin-bottom: 5px;
            }
            .subtitle {
                color: var(--color-secondary-menium);
                font-style: italic;
                font-size: 1rem;
                line-height: 22px;
            }
            p {
                color: var(--color-secondary-light);
                line-height: 22px;
                font-size: 1rem;
                margin-top: 5px;
                text-align: justify;
                margin-right: 10px;
            }
        }
    }
}

.container-discover {
    background-color: var(--color-tertiary-gray);
    height: auto;
    &:first-child {
        margin: 2rem 1rem 2rem 0rem;
    }
    &:nth-child(2) {
        margin: 2rem 0rem 2rem 1rem;
    }
}

@media (max-width: 1250px) {
    .carrucel-discover .picsum-img-wrapper .imgen-discover {
        // width: 90%;
        // height: 245px;
        object-fit: var(--object-fit);
        padding: 1.5rem 0 0 0;
    }
    .carrucel-discover .picsum-img-wrapper {
        flex-direction: column;
    }

    .carrucel-discover .picsum-img-wrapper .discover-description {
        width: 90%;
    }
    .carrucel-discover .carousel-indicators {
        bottom: -13px;
    }
    .carrucel-discover .picsum-img-wrapper .discover-description {
        margin: 1rem 1rem 2rem 0;
    }
    .discover-description {
        h3 {
            font-size: 1.2rem !important;
        }
        span {
            font-size: 1rem;
        }
    }
}

@media (max-width: 950px) {
    .container-discover {
        margin: 1rem 1rem 0 1rem !important;
    }
}

@media (max-width: 500px) {
    .carrucel-discover {
        .picsum-img-wrapper {
            .imgen-discover {
                width: 100%;
                padding: 0 0 0 0;
            }
            .discover-description {
                width: 85%;
            }
        }
    }
}
