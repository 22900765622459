.disabled-button {
    border: 1px solid var(--color-tertiary-menium) !important;
    color: var(--color-tertiary-light) !important;
    cursor: not-allowed;
    &:hover {
        background-color: var(--color-contrast) !important;
    }
}

.btn-refresh {
    border: none;
    background: none;
    position: absolute;
    right: 5px;
    top: -3px;
    svg {
        width: 20px;
        height: 20px;
        fill: var(--color-secondary-menium);
    }
}

.btn-refresh-normative {
    border: none;
    background: none;
    right: 5px;
    svg {
        width: 20px;
        height: 20px;
        fill: var(--color-secondary-menium);
    }
}

.icon-mat-menu {
    width: 20px;
    height: 20px;
    fill: var(--color-secondary-dark);
    margin-right: 10px;
}

.badge-price {
    margin-left: 0.5rem;
    color: var(--color-primary-dark);
    background-color: #e5d6c34d;
    padding: 0 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}
