.container-router {
  margin-right: auto;
  margin-left: auto;
  scroll-snap-align: start;
  padding-top: 0.5rem;
}

@media (min-width: 1400px) {
  .container-router {
    width: 1450px;
  }
}

@media (min-width: 320px) and (max-width: 700px) {
  .container-router {
    padding: 0;
  }
}

.container-router-restricted {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 1400px) {
  .container-router-restricted {
    width: calc(1450px -320px);
  }
}

@media (min-width: 320px) and (max-width: 700px) {
  .container-router-restricted {
    padding: 0;
  }
}

// restricted

.container-restrinted-body {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 1650px) {
  .container-restrinted-body {
    width: 1250px;
  }
}
@media (max-width: 1649px) {
  .container-restrinted-body {
    width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 700px) {
  .container-restrinted-body {
    padding: 0;
  }
}

// profile
.container-profile-body {
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 1700px) {
  .container-profile-body {
    width: 1560px;
  }
}

@media (min-width: 700px) and (max-width: 1699px) {
  .container-profile-body {
    width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 700px) {
  .container-profile-body {
    padding: 0;
    width: 100%;
  }
}

@media (max-width: 320px) {
  .container-profile-body {
    width: 100%;
  }
}
