.skeleton-large {
    width: 100%;
    &__head {
        width: 100%;
        // --> Convertir a porcentaje desde el 44
        height: 7px;
        margin-bottom: 5px;
        border-radius: var(--rad-small);
    }

    &__body {
        width: 100%;
        height: 45px;
        border-radius: var(--rad-small);
    }

    &__square {
        width: 40px;
        margin-right: 10px;
        height: 100%;
        border-radius: var(--rad-small);
    }
}
