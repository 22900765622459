#scroll-body::-webkit-scrollbar-track {
	//border-radius: 3px;
	background: #dddddd;
	scrollbar-width: none;
	box-shadow: inset 0 0 14px 14px transparent;
}
#scroll-body {
	scrollbar-color: var(--color-tertiary-light) transparent !important;
	overflow-y: auto;
	scrollbar-width: thin;
}

#scroll-body::-webkit-scrollbar {
	width: 12px;
	background-color: var(--color-tertiary-light-shade);
	scrollbar-width: none;
}

#scroll-body::-webkit-scrollbar-thumb {
	//border-radius: 5px;
	background-color: var(--color-tertiary-dark-shade);
	scrollbar-width: none;
	&:hover {
		transition: all 0.3s linear;
		background-color: var(--color-secondary-dark-shade);
	}
}

#scroll-body-info::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0px 0px 10px -3px var(--color-tertiary-light-shade);
	border-radius: 3px;
	background-color: var(--color-tertiary-light-shade);
	scrollbar-width: none;
}

#scroll-body-info::-webkit-scrollbar {
	width: 5px;
	height: 10px;
	background-color: var(--color-tertiary-light-shade);
	scrollbar-width: none;
}

#scroll-body-info::-webkit-scrollbar-thumb {
	border-radius: 3px;
	-webkit-box-shadow: inset 0 0 6px var(--color-tertiary-dark-shade);
	background-color: var(--color-tertiary-dark-shade);
	scrollbar-width: none;
}

#scroll-body-info {
	scrollbar-color: var(--color-tertiary-dark-shade) transparent !important;
	overflow-y: scroll;
	scrollbar-width: thin;
}

#scroll-colap::-webkit-scrollbar-track {
	border-radius: 3px;
	background-color: var(--color-tertiary-dark-shade);
	scrollbar-width: none;
}

#scroll-colap::-webkit-scrollbar {
	width: 3px;
	height: 10px;
	background-color: var(--color-tertiary-dark-shade);
	scrollbar-width: none;
}

#scroll-colap::-webkit-scrollbar-thumb {
	border-radius: 3px;
	background-color: var(--color-tertiary-dark-shade);
	scrollbar-width: none;
}

#scroll-colap {
	scrollbar-color: var(--color-tertiary-dark-shade) transparent !important;
	overflow-y: scroll;
	scrollbar-width: thin;
}

#scroll-colap-info::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0px 0px 10px -3px #d6dce8;
	border-radius: 3px;
	background-color: transparent;
	scrollbar-width: none;
}

#scroll-colap-info::-webkit-scrollbar {
	width: 3px;
	height: 10px;
	background-color: transparent;
	scrollbar-width: none;
}

#scroll-body-info::-webkit-scrollbar-thumb {
	border-radius: 3px;
	scrollbar-width: none;
}

#scroll-colap-info {
	scrollbar-color: transparent transparent !important;
	overflow-y: scroll;
	scrollbar-width: thin;
}

// activities
#scroll-activities::-webkit-scrollbar-track {
	border-radius: 3px;
	background-color: var(--color-secondary-dark);
	scrollbar-width: none;
}

#scroll-activities::-webkit-scrollbar {
	width: 5px;
	height: 10px;
	background-color: var(--color-secondary-dark);
	scrollbar-width: none;
}

#scroll-activities::-webkit-scrollbar-thumb {
	border-radius: 3px;
	background-color: var(--color-secondary-light);
	scrollbar-width: none;
}

#scroll-activities {
	scrollbar-color: var(--color-secondary-light) transparent !important;
	overflow-y: scroll;
	scrollbar-width: thin;
}

#scroll-activities-info::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0px 0px 10px -3px var(--color-secondary-dark);
	border-radius: 3px;
	background-color: var(--color-secondary-dark);
	scrollbar-width: none;
}

#scroll-activities-info::-webkit-scrollbar {
	width: 5px;
	height: 10px;
	background-color: var(--color-secondary-dark);
	scrollbar-width: none;
}

#scroll-activities-info::-webkit-scrollbar-thumb {
	border-radius: 3px;
	-webkit-box-shadow: inset 0 0 6px var(--color-secondary-light);
	background-color: var(--color-secondary-light);
	scrollbar-width: none;
}

#scroll-activities-info::-webkit-scrollbar-thumb {
	border-radius: 3px;
	scrollbar-width: none;
}
#scroll-activities-info {
	scrollbar-color: var(--color-secondary-light) transparent !important;
	overflow-y: scroll;
	scrollbar-width: thin;
}

#scroll-body2::-webkit-scrollbar-track {
	border-radius: 3px;
	background-color: transparent;
	scrollbar-width: none;
}
#scroll-body2 {
	scrollbar-color: var(--color-secondary-menium) transparent !important;
	overflow-y: scroll;
	scrollbar-width: thin;
}

#scroll-body2::-webkit-scrollbar {
	width: 7px;
	height: 10px;
	background-color: transparent;
	scrollbar-width: none;
}

#scroll-body2::-webkit-scrollbar-thumb {
	border-radius: 3px;
	background-color: var(--color-secondary-menium);
	scrollbar-width: none;
}

#scroll-body2-info::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0px 0px 10px -3px var(--color-contrast);
	border-radius: 3px;
	background-color: var(--color-contrast);
	scrollbar-width: none;
}

#scroll-body2-info::-webkit-scrollbar {
	width: 5px;
	height: 10px;
	background-color: var(--color-contrast);
	scrollbar-width: none;
}

#scroll-body2-info::-webkit-scrollbar-thumb {
	border-radius: 3px;
	-webkit-box-shadow: inset 0 0 6px var(--color-contrast);
	background-color: var(--color-tertiary-light);
	scrollbar-width: none;
}

#scroll-body2-info {
	scrollbar-color: var(--color-tertiary-light) transparent !important;
	overflow-y: scroll;
	scrollbar-width: thin;
}

// activities
#scroll-notifications::-webkit-scrollbar-track {
	background: rgba(172, 172, 172, 0.476); /* color of the tracking area */
	border-radius: 20px;
}

#scroll-notifications::-webkit-scrollbar {
	width: 7px;
	height: 10px;
	background-color: var(--color-contrast);
	scrollbar-width: none;
}

#scroll-notifications::-webkit-scrollbar-thumb {
	background-color: rgba(150, 150, 150, 0.849); /* color of the scroll thumb */
	border-radius: 20px; /* roundness of the scroll thumb */
}

#scroll-notifications {
	scrollbar-color: var(--color-secondary-light) transparent !important;
	overflow-y: auto;
	overflow-x: hidden;
	scrollbar-width: thin;
}

//detail evetn
#scroll-detail-event::-webkit-scrollbar-track {
	border-radius: 3px;
	background-color: var(--color-tertiary-gray);
	scrollbar-width: none;
}

#scroll-detail-event::-webkit-scrollbar {
	width: 5px;
	height: 10px;
	background-color: var(--color-tertiary-gray);
	scrollbar-width: none;
}

#scroll-detail-event::-webkit-scrollbar-thumb {
	border-radius: 3px;
	background-color: var(--color-secondary-light);
	scrollbar-width: none;
}

#scroll-detail-even {
	scrollbar-color: var(--color-secondary-light) transparent !important;
	overflow-y: scroll;
	scrollbar-width: thin;
}
