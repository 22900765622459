.content-options-menu {
    display: grid;
    grid-template-columns: 165px 165px 165px 165px;
    grid-template-rows: auto;
    justify-content: center;
    gap: 10px;
    grid-row-gap: 10px;
    align-items: flex-start;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .card-item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 150px;
        flex-direction: column;
        border-radius: 5px;
        opacity: 0;
        min-height: 180px;
        gap: 10px;
        &_icon {
            width: 80px;
            height: 80px;
            fill: var(--color-primary-dark);
        }
        h3 {
            font-size: 1.1rem;
            text-align: center;
            // margin-top: 1rem;
            margin-bottom: 0;
            font-family: "lato-italic";
            color: var(--color-secondary-dark-shade);
            height: 37px;
            line-height: 18px;
        }
        @keyframes rebote {
            20%,
            50%,
            70% {
                transform: translateY(0%);
            }
            40% {
                transform: translateY(-20px);
            }
            60% {
                transform: translateY(-10px);
            }
        }
    }
}

@media (max-width: 1100px) {
    .content-options-menu {
        grid-template-columns: 165px 165px 165px;
    }
}
@media (max-width: 1024px) {
    .content-options-menu {
        grid-template-columns: 165px 165px 165px 165px;
    }
}
@media (max-width: 900px) {
    .content-options-menu {
        grid-template-columns: 165px 165px 165px;
    }
}

@media (max-width: 670px) {
    .content-options-menu {
        grid-template-columns: 165px 165px 165px;
        gap: 10px;
        .card-item {
            &_icon {
                width: 75px;
                height: 75px;
            }
            h3 {
                font-size: 1rem;
            }
        }
    }
}

@media (max-width: 480px) {
    .content-options-menu {
        grid-template-columns: 165px 165px;
        gap: 10px;
    }
}

// second section

.container-cards-menu {
    display: grid;
    grid-template-columns: 40% 20% 40%;
    grid-template-rows: auto;
    grid-gap: 10px;
    margin: 1rem 1rem 1rem 1rem;
    .title {
        text-align: center;
        margin: 0;
        font-size: 1.1rem;
        color: var(--color-secondary-dark-shade);
        font-weight: 400;
    }
    .content-note-outstanding {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .container-note {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: var(--color-tertiary-gray);
            padding: 1rem;
            height: 165px;
            border-radius: var(--radius-images);
            &_icon {
                width: 90px;
                margin-right: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                svg {
                    fill: var(--color-primary-dark);
                    width: 60px;
                    height: 60px;
                }
            }
            &_description {
                display: flex;
                flex-direction: column;
                gap: 5px;
                width: calc(100% - 90px);
                h3 {
                    margin-bottom: 0;
                    font-size: 1.1rem;
                    color: var(--color-primary-dark);
                    font-weight: 400;
                }
                span {
                    color: var(--color-tertiary-dark-shade);
                    font-size: 1rem;
                }
                p {
                    color: var(--color-tertiary-dark-shade);
                    line-height: 16px;
                    font-size: 0.8rem;
                    margin-bottom: 10px;
                }
            }
        }
    }
    .content-publications {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}

.container-publication {
    background-color: var(--color-tertiary-gray);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
    gap: 10px;
    border-radius: var(--radius-images);

    &_icon {
        fill: var(--color-primary-dark);
        width: 65px;
        height: 65px;
        margin-top: 1rem;
    }
    &_title {
        color: var(--color-tertiary-dark);
        font-size: 1rem;
        text-align: center;
    }
}

.content-events-next {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .container-next-event {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--color-tertiary-gray);
        height: 165px;
        border-radius: var(--radius-images);

        &_image {
            width: 50%;
            height: 163px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: var(--radius-images) 0 0 var(--radius-images);
            }
        }
        &_description {
            height: 100%;
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 1rem 0;
            h3 {
                color: var(--color-primary-dark);
                font-size: 1.1rem;
            }
            span {
                color: var(--color-tertiary-dark-shade);
                text-align: center;
                font-size: 1rem;
            }
            .container-cards_button {
                button {
                    margin-right: 0;
                    line-height: 17px;
                    padding: 5px 10px;
                }
                margin-top: 0.5rem;
            }
        }
    }
}
.btn-clear-filter {
    display: flex;
    justify-content: end;
    align-items: center;
    margin: 0 4rem;
    margin-top: 1rem;
    button {
        border: none;
        background-color: transparent;
        svg {
            width: 25px;
            height: 25px;
            fill: var(--color-secondary-menium);
        }
    }
}
@media (max-width: 1260px) {
    .container-cards-menu {
        grid-template-columns: 60% 40%;
    }
}

@media (max-width: 1024px) {
    .container-cards-menu {
        grid-template-columns: 40% 20% 40%;
    }
}

@media (max-width: 950px) {
    .container-cards-menu {
        grid-template-columns: 60% 40%;
    }
}

@media (max-width: 650px) {
    .container-cards-menu {
        grid-template-columns: 100%;
        .container-note {
            height: auto !important;
        }
    }
}

@media (min-width: 1400px) {
    .wrapper-home {
        width: 1350px;
    }
}

@media (max-width: 1400px) {
    .wrapper-home {
        width: 100%;
    }
}
