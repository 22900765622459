.container-search {
    margin: 1rem 2rem;
    position: relative;
    padding: 1rem;
    border-bottom: 1px solid var(--color-tertiary-light);
    h3 {
        color: var(--color-secondary-menium);
        font-size: 1.2rem;
        margin-bottom: 1rem;
        width: calc(100% - 130px);
    }
    span {
        color: var(--color-secondary-light);
        font-size: 1.2rem;
        margin-bottom: 2rem;
        position: absolute;
        top: 15px;
        right: 25px;
    }
    p {
        color: var(--color-secondary-light);
        line-height: 22px;
        width: 100%;
        position: relative;
        top: 20px;
    }
}
.container-search:nth-last-child(-n + 1) {
    border-bottom: none !important;
    margin-bottom: 3rem !important;
}

.search-filter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding-right: 3rem;
    button {
        height: 40px;
        width: 40px;
        margin-left: 5px;
        background-color: transparent;
        border: none;
        svg {
            width: 25px;
            height: 25px;
            fill: var(--color-secondary-menium);
            transition: all 0.3s linear;
            &:hover {
                fill: var(--color-primary-dark-shade);
            }
        }
    }
}

.titleBanner {
    color: #d1b47c;
    background-color: #d1b47c1a;
    padding: 3px 0.8em;
    margin-right: 5px;
    border-radius: 5px;
}
