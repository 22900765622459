.loader-animations {
    display: flex;
    justify-content: center;
    align-self: center;
    margin: 0 auto;
    position: absolute;
    width: 100%;
    top: 35%;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
}
.loader-animations > div {
    width: 1.3rem;
    height: 1.3rem;
    margin: 3rem 0.5rem;
    background-color: var(--color-secondary-dark);
    border-radius: 50%;
    animation: loader-animation 0.6s infinite alternate;
}

@keyframes loader-animation {
    to {
        opacity: 0.1;
        transform: translate3d(0, -1rem, 0);
    }
}

.loader-animations > div:nth-child(2) {
    animation-delay: 0.2s;
}

.loader-animations > div:nth-child(3) {
    animation-delay: 0.4s;
}


 @media (max-width:800px){
    .loader-animations {
        div {
            width: 1rem;
            height: 1rem;
        }   
    }
 }