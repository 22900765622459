.product-item {
    .product-image {
        width: 50%;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }
}

.p-carousel-indicators {
    display: none !important;
}

.container-multiitems {
    margin: 2rem 0.5rem;
}

.container-slider-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    position: relative;
    &_images {
        width: 320px !important;
        height: 135px !important;
        aspect-ratio: 10/4;
        .image {
            object-fit: cover;
            width: 100%;
            height: 100%;
            border-radius: var(--radius-img);
        }
    }

    &_title {
        margin: 1rem 2.5rem;
        color: var(--color-secondary-menium);
        font-size: 1rem;
        font-family: "lato-bold" !important;
        text-align: center;
    }
}

.container-slider-items {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    position: relative;
    &_images {
        width: 320px !important;
        height: 190px !important;
        aspect-ratio: 10/4;
        .image {
            object-fit: cover;
            width: 100%;
            height: 100%;
            border-radius: var(--radius-img);
        }
    }

    &_title {
        margin: 1rem 2.5rem;
        color: var(--color-secondary-menium);
        font-size: 1rem;
        font-family: "lato-bold" !important;
        text-align: center;
    }
}

.imagen-carrucel-entities {
    width: 300px !important;
    height: 170px !important;
}

.container-slider-menu {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    position: relative;
    &_images {
        width: 250px;
        height: 150px;
        .image {
            object-fit: cover;
            width: 100%;
            height: 100%;
            border-radius: var(--radius-img);
        }
    }

    &_title {
        margin: 5px;
        color: var(--color-secondary-dark-shade);
        font-size: 1rem;
        text-align: center;
    }
}

.container-slider-item-generic {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    position: relative;
    &_images {
        width: 250px !important;
        height: 150px !important;
        aspect-ratio: 5/3 !important;
        .image {
            object-fit: cover;
            width: 100%;
            height: 100%;
            border-radius: var(--radius-img);
        }
        video {
            border-radius: var(--radius-img);
        }
        iframe {
            border-radius: var(--radius-img);
        }
    }

    &_title {
        margin: 1rem 2.5rem;
        color: var(--color-secondary-menium);
        font-size: 1rem;
        font-family: "lato-bold" !important;
        text-align: center;
    }
}

.p-carousel-next,
.p-carousel-prev {
    align-items: center !important;
    align-self: center !important;
    display: flex !important;
    flex-grow: 0 !important;
    flex-shrink: 0 !important;
    justify-content: center;
    overflow: hidden !important;
    position: relative !important;
    background-image: url("/../assets/images/forward-right.png") !important;
    width: 25px !important;
    height: 25px !important;
    background-color: transparent !important;
    border: none !important;
    background-repeat: no-repeat !important;
    background-size: 20px !important;
    border-radius: 0 !important;
    span {
        display: none;
    }
    &:focus {
        box-shadow: none !important;
    }
}
.p-carousel-prev {
    transform: scaleX(-1);
}

// publications carrucel
.cards-item {
    .container-slider-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        height: 100%;
        &_images {
            // width: 235px;
            // height: 230px;
            // aspect-ratio: 6/6;
            width: 300px;
            height: 170px;
            aspect-ratio: 10/4;
            .image {
                object-fit: cover;
                width: 100%;
                height: 100%;
                border-radius: var(--radius-img);
            }
        }

        &_title {
            margin: 1rem 2rem;
            color: var(--color-secondary-menium);
            font-size: 1.1rem;
            font-family: "lato-bold" !important;
        }
        &_description {
            width: 75%;
            p {
                text-align: justify;
                color: var(--color-secondary-light);
                line-height: 22px;
                margin-top: 0rem;
            }
        }
    }
}
.container-body-card {
    margin-bottom: 2.5rem;
}

@media (max-width: 780px) {
    .container-multiitems {
        margin: 0.5rem;
    }

    .container-slider-item {
        &_title {
            margin-top: 1rem;
            color: var(--color-secondary-menium);
            font-size: 1.1rem;
            font-family: "lato-bold" !important;
        }
    }

    .cards-item {
        .container-slider-item {
            &_images {
                //width: 95%;
                // height: 230px;
            }
            &_description {
                width: 95%;
            }
        }
    }

    .container-slider-item {
        &_images {
            height: 150px;
        }
    }
}

.link-page-web {
    &:hover {
        color: var(--color-primary-dark-shade);
        font-family: "lato";
    }
}

.cards-item {
    height: 100%;
}

.cards-item-content {
    height: 100%;
}

@media (max-width: 450px) {
    .cards-item .container-slider-item_images {
        width: 250px;
        height: 130px;
    }
}

@media (max-width: 420px) {
    .cards-item .container-slider-item_images {
        width: 210px !important;
        height: 100px !important;
    }
    .container-slider-item_title {
        margin: 0.5rem 5px !important;
    }
}

@media (max-width: 420px) {
    .container-slider-item_images {
        width: 210px !important;
        height: 100px !important;
    }
}

@media (max-width: 320px) {
    .container-slider-item_images,
    .cards-item .container-slider-item_images {
        width: 180px !important;
        height: 100px !important;
    }
}

@media (max-width: 420px) {
    .container-multi-card .content-card_image {
        width: 210px !important;
        height: 100px !important;
    }
}

.initiative-image {
    width: 320px !important;
    height: 135px !important;
    aspect-ratio: 10/4;
    .image {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: var(--radius-img);
    }
}
