:root {
    //golden color
    --color-primary-dark: #d39e46;
    --color-primary-dark-shade: #d1b47c;
    --color-primary-menium: #d9c6a7;
    --color-primary-light: #e5d6c3;
    --color-primary-light-shade: #f1eae0;

    //blue color
    --color-secondary-dark: #272d44;
    --color-secondary-dark-shade: #333d60;
    --color-secondary-menium: #676d87;
    --color-secondary-light: #989faf;
    --color-secondary-light-shade: #c8ccd5;
    --color-secondary-contrast: #c8ccd573;
    --color-secondary-contrast-shade: #c8ccd538;

    //gray color
    --color-tertiary-dark: #5d5d60;
    --color-tertiary-dark-shade: #797978;
    --color-tertiary-menium: #a3a3a2;
    --color-tertiary-light: #cccccd;
    --color-tertiary-light-shade: #eaeaea;
    //#f8f8f8
    --color-tertiary-gray: #f7f7f7;
    --color-tertiary-shade: #c8ccd540;

    --color-contrast: #ffffff;
    --color-contrast-shade: #dee2e6;
    --color-dark: rgb(0, 0, 0);

    --radius-img: 3px;
    --radius-video: 5px;
    --radius-images: 5px;

    --object-fit: fill;
    --border-btn: 1.5px;

    --skeleton-grad: linear-gradient(to right, rgb(234 236 239) 8%, rgb(229 230 232) 18%, rgb(233 235 237) 33%);
    --skeleton-grad-white: linear-gradient(
        to right,
        rgba(152, 159, 175, 0.2) 8%,
        rgba(152, 159, 175, 0.4) 18%,
        rgba(152, 159, 175, 0.2) 33%
    );

    --z-index-level-0: 1;
    --z-index-level-1: 10;
    --z-index-level-2: 100;
    --z-index-level-3: 1000;
    --z-index-level-4: 10000;

    --box-shadow: 0 3.2px 7.2px 0 var(--callout-shadow-color, rgba(0, 0, 0, 0.132)),
        0 0.6px 1.8px 0 var(--callout-shadow-secondary-color, rgba(0, 0, 0, 0.108));
}
