.success-toast {
    background-color: #def3ec;
    color: #1ea97c;
    box-shadow: none;
    .mat-button-wrapper {
        color: #1ea97c;
    }
}

.info-toast {
    background-color: #12508725;
    color: #125087;
    box-shadow: none;
    .mat-button-wrapper {
        color: #125087;
    }
}

.warning-toast {
    background-color: var(--color-primary-light-shade);
    color: var(--color-primary-dark);
    box-shadow: none;
    .mat-button-wrapper {
        color: var(--color-primary-dark);
    }
}

.warning-toast-suscription {
    background-color: var(--color-primary-light-shade);
    color: var(--color-primary-dark);
    box-shadow: none;
    .mat-button-wrapper {
        color: var(--color-primary-dark);
    }
}

.error-toast {
    background-color: #f9e4e5;

    color: #d4464e;
    box-shadow: none;

    .mat-button-wrapper {
        color: #d4464e;
    }
}

.success-login-toast {
    background-color: #def3ec;
    color: #1ea97c;
    box-shadow: none;
    margin-right: 320px !important;

    .mat-button-wrapper {
        color: #1ea97c;
    }
}

@media (max-width: 1024px) {
    .success-login-toast {
        margin-right: 0px !important;
    }
}

@media (max-width: 600px) {
    .success-login-toast {
        margin-top: 9px !important;
        padding: 17px 16px !important;
    }
}

.container-message {
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 0.8rem;
    margin-top: 5px;
    text-align: justify;
    a {
        color: var(--color-secondary-dark);
        font-family: "lato-bold";
        &:hover {
            color: var(--color-secondary-dark);
        }
    }

    &--gold {
        background-color: var(--color-primary-light-shade);
        color: var(--color-primary-dark);
    }

    &--blue {
        background-color: var(--color-secondary-contrast);
        color: var(--color-secondary-dark);
    }

    &--green {
        background-color: #def3ec;
        color: #1ea97c;
    }

    &--red {
        background-color: #f9e4e5;
        color: #d4464e;
    }
}
