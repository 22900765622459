.container-banner-images {
    width: 100%;
    aspect-ratio: 15/6;
    transition: all 0.3s linear;
    position: relative;
    .image-banner {
        object-fit: cover;
        width: 100%;
        height: 100%;
        width: 100%;
    }
    .banner-title {
        position: absolute;
        bottom: 10px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-right: 25px;
        right: 0;
        h1 {
            color: var(--color-contrast);
            right: 25px;
            font-size: 2rem;
            font-weight: 400;
            text-transform: uppercase;
        }
    }
}

@media (max-width: 1160px) {
    .container-banner-images {
        //height: 500px;
        .banner-title {
            h1 {
                font-size: 1.8rem;
            }
        }
    }
}

@media (max-width: 800px) {
    .container-banner-images {
        //height: 400px;
        .banner-title {
            h1 {
                font-size: 1.6rem;
            }
        }
    }
}

@media (max-width: 500px) {
    .container-banner-images {
        // height: 300px;
        .banner-title {
            h1 {
                font-size: 1.4rem;
            }
        }
    }
}
