.container-title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1.5rem 1rem 1rem 1rem;
    svg {
        width: 30px;
        height: 30px;
        margin-right: 5px;
        fill: var(--color-secondary-dark-shade);
    }
    h4 {
        text-align: center;
        margin: 0;
        color: var(--color-secondary-menium);
        font-weight: 400;
    }
}

@media (max-width: 700px) {
    .container-title {
        h4 {
            font-size: 1.2rem;
        }
        svg {
            width: 25px;
            height: 25px;
        }
    }
}
