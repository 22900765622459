.component-description {
    width: 100%;
    margin-top: 1rem;
    line-height: 22px;
    color: var(--color-secondary-light);
    text-align: justify;
}

.component-subtitle {
    font-weight: 500;
    color: var(--color-secondary-menium);
    margin-top: 0.3rem;
    font-size: 1.1rem;
    text-align: start;
    line-height: 22px;
}
.component-title {
    color: var(--color-primary-dark);
    font-size: 1.3rem;
}

.component-title-blue {
    color: var(--color-secondary-menium) !important;
}

.upperCase-letter {
    text-transform: uppercase;
}

.difuminado {
    background: linear-gradient(0deg, #ffffff 0, hsla(0, 0%, 100%, 0.4) 95%, hsla(0, 0%, 100%, 0));
    z-index: 1;
    height: 20px;
    margin-top: 5px;
    position: relative;
    top: -20px;
    margin-bottom: -1rem;
    margin-right: 5px;
    width: 100%;
    right: 5px;
}

.difuminado-event {
    background: linear-gradient(0deg, #ffffff 0, hsla(0, 0%, 100%, 0.4) 95%, hsla(0, 0%, 100%, 0));
    z-index: 1;
    height: 20px;
    position: relative;
    top: -10px;
    margin-bottom: -1rem;
    margin-right: 5px;
    width: 100%;
    right: 5px;
}

//modal
@media (max-width: 800px) {
    .difuminado-event,
    .difuminado {
        display: none;
    }
}

.content-publishAt {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: var(--color-secondary-menium);
    margin-right: 5px;
    margin-bottom: 1rem;
}
