.carrucel-events {
    display: block;
    width: 45%;
    position: relative;
    top: -90px;
}

.container-carrucel-events {
    height: 360px;
    margin-top: 12rem;
    margin-bottom: 1.5rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .carousel-indicators {
        bottom: -55px;
    }
    &::after {
        content: "";
        border-top-left-radius: 50% 100%;
        border-top-right-radius: 50% 100%;
        position: absolute;
        bottom: 0;
        z-index: -1;
        width: 100%;
        background-color: #0e0ebe;
        height: 85%;
    }
}

.carousel-indicators li {
    height: 13px !important;
    border-radius: 50% !important;
    width: 13px !important;
}
.carousel-indicators .active {
    background-color: var(--color-secondary-menium) !important;
}

.picsum-img-wrapper-event {
    height: 400px;
    .imagen-carrucel-event {
        width: 100%;
        height: 330px;
        object-fit: cover;
        border-radius: var(--radius-img);
    }
}

.container-carrucel-events {
    background-color: var(--color-secondary-dark);
}

.carrucel-description {
    text-align: center;
    margin-top: -115px;
    color: var(--color-contrast);
    position: absolute;
    bottom: -20px;
    width: 100%;
    h3 {
        margin-bottom: 0;
        font-size: 1.4rem;
    }
    p {
        font-size: 1.1rem;
    }
}
.truncate-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

@media (max-width: 1000px) {
    .carrucel-events {
        width: 60%;
    }
}

@media (max-width: 800px) {
    .carrucel-events {
        width: 65%;
    }

    .picsum-img-wrapper-event {
        height: 375px;
        .imagen-carrucel-event {
            height: 310px;
        }
    }

    .container-carrucel-events {
        height: 315px;
    }
}

@media (max-width: 700px) {
    .carrucel-events {
        width: 70%;
    }
    .picsum-img-wrapper-event {
        height: 355px;
        .imagen-carrucel-event {
            height: 290px;
        }
    }
    .carrucel-description h3 {
        font-size: 1.2rem;
    }

    .container-carrucel-events {
        height: 300px;
    }
}

@media (max-width: 600px) {
    .carrucel-events {
        width: 70%;
    }
    .picsum-img-wrapper-event {
        height: 330px;
        .imagen-carrucel-event {
            height: 260px;
        }
    }

    .container-carrucel-events {
        height: 280px;
    }

    .carrucel-description h3 {
        font-size: 1.2rem;
    }
}

@media (max-width: 400px) {
    .carrucel-events {
        width: 75%;
    }
    .picsum-img-wrapper-event {
        height: 270px;
        .imagen-carrucel-event {
            height: 200px;
        }
    }

    .container-carrucel-events {
        height: 215px;
    }
    .carrucel-description h3 {
        font-size: 1.2rem;
    }
}
