.atm-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    mat-form-field {
        margin-left: 10px;
        width: 145px;
    }
    &-title {
        margin-right: 23px;
        font-size: 1.2rem;
        color: var(--color-secondary-menium);
    }

    .button-search {
        border: none;
        background: transparent;
        margin-left: 5px;
        svg {
            width: 20px;
            height: 20px;
            fill: var(--color-tertiary-dark-shade);
            transition: all 0.3s linear;
        }
        &:hover {
            svg {
                fill: var(--color-primary-dark);
            }
        }
    }
}

#map-branch {
    width: 96%;
    height: 520px;
    margin: 1rem 1rem 1rem 1rem;
}

@media (max-width: 700px) {
    #map-branch {
        width: 93%;
        height: 450px;
    }
}

@media (max-width: 549px) {
    #map-branch {
        width: 94%;
        height: 400px;
    }
}

@media (max-width: 415px) {
    #map-branch {
        width: 94%;
        height: 350px;
    }
}

@media (max-width: 1215px) {
    .atm-header {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
        margin-bottom: 1rem;
        &-filter {
            margin-top: 1rem;
        }
    }
}

@media (max-width: 888px) {
    .atm-header {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
        &-filter {
            margin-top: 1rem;
        }
    }
}

.button-filter-state {
    background-color: transparent;
    width: 145px;
    min-width: 106px;
    height: 43px;
    margin-left: 0.5rem;
    border-radius: 5px;
    transition: all 0.3s linear;
    padding-left: 20px;
    padding-right: 20px;
    border: 1px solid var(--color-tertiary-light);
    margin-bottom: 10px;
    span {
        margin-left: 0.5rem;
        color: var(--color-tertiary-dark-shade);
    }
    svg {
        width: 15px;
        height: 15px;
        margin-left: 6px;
        margin-top: -5px;
        fill: var(--color-secondary-light) !important;
    }
}
.menu-filter {
    color: var(--color-tertiary-menium);
}

.svg-check-button {
    width: 15px;
    height: 15px;
    margin-right: 6px;
    transition: all 0.3s linear;
    visibility: hidden;
    fill: var(--color-tertiary-menium);
}
.check-option {
    color: var(--color-secondary-dark);
    background-color: #272d4418;
    svg {
        opacity: 1;
        fill: var(--color-secondary-dark);
        visibility: visible;
    }
}
