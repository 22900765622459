.button-close {
    background-color: transparent;
    border: none;
    width: 35px;
    height: 35px;
    position: absolute;
    right: 2px;

    svg {
        width: 15px;
        height: 15px;
    }
}

.button-close-other {
    background-color: transparent;
    border: none;
    width: 35px;
    height: 35px;
    position: absolute;
    right: 5px;
    top: 5px;
    svg {
        width: 15px;
        height: 15px;
        fill: var(--color-secondary-dark);
    }
}

.button-close-schedule {
    background-color: transparent;
    border: none;
    width: 35px;
    height: 35px;
    position: absolute;
    right: 10px;

    svg {
        width: 15px;
        height: 15px;
    }
}

.container-modal-entities {
    margin: 3rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &_image {
        width: 260px;
        height: 160px;
        img {
            width: 100%;
            height: 100%;
            object-fit: var(--object-fit);
        }
    }
    h3 {
        font-weight: 400;
        color: var(--color-secondary-menium);
        margin: 1rem 0 0 0;
    }
    &_description {
        margin-top: 1rem;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        width: 75%;
        p {
            margin: 0;
            color: var(--color-secondary-menium);
            text-align: start;
            font-size: 1.1rem;
            width: calc(100% - 145px);
        }
        a {
            text-align: center;
            text-decoration: none !important;
            color: var(--color-secondary-menium);
            font-size: 1rem;
            transition: all 0.3s linear;
            border: var(--border-btn) solid var(--color-secondary-dark);
            background-color: none;
            color: var(--color-secondary-dark);
            padding: 0.3rem 0.8rem;
            border-radius: 5px;
            &:hover {
                color: var(--color-contrast) !important;
            }
        }
        .container-entity-description {
            display: flex;
            justify-content: start;
            align-items: center;
            span {
                width: 145px;
                font-weight: 600;
                color: var(--color-secondary-dark-shade);
            }
        }
        .container-modal-entities_link {
            width: 100%;
            text-align: center;
            margin-top: 1.5rem;
            a {
                &:hover {
                    color: var(--color-secondary-dark) !important;
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .container-modal-entities {
        &_image {
            width: 150px;
            height: 140px;
            svg {
                width: 90px;
                height: 90px;
            }
        }
        h3 {
            font-size: 1.4rem;
        }
        p {
            font-size: 1rem;
        }
    }
}

.title-preview-document {
    color: var(--color-secondary-menium);
    height: var(--h-modal-header, 50px);
    text-align: center;
    margin-top: 5px;
    font-size: 1.4rem;
    padding: 15px;
}

.modal-fade {
    transition: opacity 0.3s;
}

.modal-fade.ng-enter,
.modal-fade.ng-leave-to {
    opacity: 0;
}
