.carousel-indicators li {
    height: 13px !important;
    border-radius: 50% !important;
    width: 13px !important;
}
.carousel-indicators .active {
    background-color: var(--color-secondary-menium) !important;
}

.container-carrucel-card {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row-reverse;
    margin: 2.5rem 2.5rem;
    .container-descriptions-press {
        width: 50%;
        position: relative;
    }

    .container-descriptions-press-img {
        width: 50%;
        height: 270px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: var(--radius-img);
        }
    }
}

.carrucel-press {
    margin: 1rem 0 3rem 0;
    .carousel-indicators {
        bottom: -10px !important;
        li {
            background-color: var(--color-tertiary-dark-shade);
        }
    }
}

.container-descriptions-press {
    margin: 0 0 1rem 2rem;
    height: 100%;
    &_title {
        color: var(--color-secondary-menium);
        font-family: "lato-bold" !important;
        margin-bottom: 0;
        font-size: 1.3rem;
    }
    &_subtitle {
        color: var(--color-secondary-menium);
        font-size: 1rem;
    }
    &_date {
        width: 100%;
        text-align: end;
        color: var(--color-secondary-menium);
        font-size: 1.1rem;
        margin: -15px 0 0 0;
        position: absolute;
        top: -10px;
    }

    &_description {
        color: var(--color-secondary-light);
        line-height: 22px;
        margin-top: 1rem;
        text-align: justify;
    }
}

@media (max-width: 1200px) {
    .container-carrucel-card {
        .container-descriptions-press {
            margin: 0 0 0 2rem;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
        }
    }
}

@media (max-width: 800px) {
    .container-carrucel-card {
        height: auto;
        flex-direction: column-reverse;
        margin: 0 1rem;
        .container-descriptions-press-img {
            width: 100%;
            height: 240px;
        }
        .container-descriptions-press {
            width: 100%;
            margin-top: 1.5rem;
            margin: 2rem 0 1rem 0 !important;
            &_title {
                font-size: 1.3rem;
            }
            &_subtitle {
                font-size: 1rem;
            }
        }

        .container-cards_button {
            margin-bottom: 1rem;
        }
    }
}

@media (max-width: 600px) {
    .container-descriptions-press-img {
        aspect-ratio: 10/4;
        height: auto !important;
    }
}
