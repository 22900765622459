.content-login {
  height: 100vh;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 150px;
    margin: auto;
    display: block;
    padding: 20px 0 10px 0;
  }
  .title-form-pass {
    font-size: 1.1rem;
    color: var(--color-tertiary-menium);
    text-align: center;
    font-weight: 100;
  }
  h6 {
    text-align: center;
    font-size: 1.5rem;
    color: var(--color-contrast);
    font-weight: 100;
  }
  &__card {
    position: relative;
    width: 370px;
    height: 500px;
    border-radius: 8px;
    background-color: var(--color-contrast);
    box-shadow: var(--box-shadow);
    &__fileds {
      padding: 0 30px;
      form {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        mat-form-field {
          width: 95%;
        }
        .forgot-password {
          cursor: pointer;
          color: var(--color-secondary-dark);
          font-style: italic;
          font-size: 0.8rem;
          position: absolute;
          right: 40px;
          margin-top: 2px;
          transition: all 0.2s linear;
          &:hover {
            color: var(--color-secondary-dark-shade);
          }
        }
      }
      .login-button {
        flex-direction: column;
      }
    }
  }
  &__logo {
    text-align: center;
    padding: 20px;
  }
  .water-mark-image {
    position: absolute;
    left: -1px;
    bottom: 0;
    width: 60px;
    opacity: 0.3;
  }
}

.icon-input {
  border: none;
  background: transparent;
  svg {
    width: 20px;
    height: 20px;
    margin-top: -5px;
    fill: var(--color-tertiary-menium);
  }
}

@media (max-width: 400px) {
  .content-login__card {
    box-shadow: none;
    width: 100%;
  }
}

.code-label {
  margin: 0;
  color: var(--color-primary-dark);
  font-size: 0.9rem;
  text-align: center;
}

.btn-back {
  position: absolute;
  top: 20px;
  left: 20px;
  border: none;
  background-color: transparent;
  border: var(--border-btn) solid var(--color-secondary-menium);
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 30px;
    height: 30px;
    transform: rotate(180deg);
    fill: var(--color-secondary-menium);
  }
}

.gradient-background {
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  background: linear-gradient(100deg, #f1eae0, #c8ccd5, #eaeaea);
  background-size: 180% 180%;
  animation: gradient-animation 9s ease infinite;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
