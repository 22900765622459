.grid-col-3 {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-template-rows: auto;
    margin: 1rem 3rem;
    transition: all 0.3s linear;
    margin-bottom: 2rem;
    gap: 15px;
}

.grids-col-2 {
    display: grid;
    grid-template-columns: 33% 33%;
    grid-template-rows: auto;
    margin: 2rem 3rem;
    transition: all 0.3s linear;
    margin-bottom: 2rem;
    gap: 15px;
    justify-content: center;
    align-items: center;
}

.grids-col-1 {
    display: grid;
    grid-template-columns: 33%;
    grid-template-rows: auto;
    margin: 2rem 3rem;
    transition: all 0.3s linear;
    margin-bottom: 2rem;
    gap: 15px;
    justify-content: center;
    align-items: center;
}

//tres columnas

@media (max-width: 1115px) {
    .grid-col-3 {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: auto;
        margin: 1rem 3rem;
    }

    .grids-col-1 {
        display: grid;
        grid-template-columns: 50%;
        grid-template-rows: auto;
        margin: 1rem 3rem;
    }
}

@media (max-width: 780px) {
    .grid-col-3 {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: auto;
        margin: 1rem 3rem;
        .container-cards {
            margin: 1rem 2.5rem 0 2.5rem !important;
            svg {
                width: 70px;
                height: 70px;
                fill: var(--color-primary-dark);
            }
        }
    }

    .grids-col-1 {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: auto;
        margin: 1rem 3rem;
    }
}

@media (max-width: 530px) {
    .grid-col-3 {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: auto;
        margin: 1rem 1rem;
        .container-cards {
            margin: 0 !important;
            p {
                margin-bottom: 2rem;
            }
        }
    }

    .grids-col-1 {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: auto;
        margin: 1rem 1rem;
        .container-cards {
            margin: 0 !important;
            p {
                margin-bottom: 2rem;
            }
        }
    }
}

//dos columnas

@media (max-width: 1115px) {
    .grids-col-2 {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: auto;
        margin: 1rem 3rem;
    }
}

@media (max-width: 780px) {
    .grids-col-2 {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: auto;
        margin: 1rem 3rem;
        .container-cards {
            margin: 1rem 2.5rem 0 2.5rem !important;
            svg {
                width: 70px;
                height: 70px;
                fill: var(--color-primary-dark);
            }
        }
    }
}

@media (max-width: 530px) {
    .grids-col-2 {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: auto;
        margin: 1rem 3rem;
        .container-cards {
            margin: 0 !important;
        }
    }
}

.container-cards {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    flex-direction: column;
    border-radius: 5px;
    margin: 1rem 2rem 1rem 2rem;
    padding: 20px 0px 10px 0px;
    svg {
        width: 80px;
        height: 80px;
        margin-bottom: 5px;
        fill: var(--color-primary-dark);
    }
    span {
        color: var(--color-secondary-menium);
        margin-top: 0.7rem;
        font-size: 1.15rem;
        text-align: center;
        line-height: 22px;
    }
    p {
        width: 100%;
        margin-top: 0.5rem;
        margin-bottom: 5px;
        line-height: 19px;
        text-align: justify;
        font-size: 1rem;
        color: var(--color-secondary-light);
    }
    &_button {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        position: relative;
        button {
            background: transparent;
            display: inline-block;
            padding: 1px 22px 1px 22px;
            border: var(--border-btn) solid var(--color-secondary-light);
            border-radius: 5px;
            transition: all 0.2s ease-in;
            position: relative;
            overflow: hidden;
            z-index: 1;
            color: var(--color-secondary-light);
            font-size: 1rem;
            &:hover {
                color: var(--color-contrast);
                background-color: var(--color-primary-dark-shade);
                border: var(--border-btn) solid var(--color-primary-dark-shade);
                //margin: 1px;
            }
        }
    }
    .button-options {
        position: absolute;
        bottom: 0px;
    }
    &:hover {
        svg {
            animation: rebote 1s infinite;
        }
    }
    @keyframes rebote {
        20%,
        50%,
        70% {
            transform: translateY(0%);
        }
        40% {
            transform: translateY(-20px);
        }
        60% {
            transform: translateY(-10px);
        }
    }
}

.grid-col-2 {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    margin: 1rem 3rem 4rem 3rem;
    transition: all 0.3s linear;
    gap: 15px;
}

@media (max-width: 1250px) {
    .grid-col-2 {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: auto;
        margin: 1rem 3rem;
    }
}

@media (max-width: 950px) {
    .grid-col-2 {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: auto;
        margin: 1rem 1rem 3rem 1rem;
    }
}

.wrapper-discover {
    margin: 1rem 0rem 4rem 0rem !important;
}
