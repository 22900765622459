$w-icon: 20px;
$size-logo: 40px;

.container-side-calendar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  button {
    background-color: transparent;
    border: none;
  }
}
.content-side-calendar {
  position: fixed;
  right: 0;
  top: 0;
  z-index: var(--z-index-level-2);
  width: 320px;
  height: 100%;
  background-color: var(--color-secondary-dark);
  color: var(--color-contrast);
  transition: width 100ms cubic-bezier(0.42, 0.01, 0.4, 1);
  overflow-x: hidden !important;
}

header {
  padding: 0.5rem;

  svg {
    width: 20px;
    height: 20px;
    fill: var(--color-contrast);
  }
}

.title-activities {
  font-size: 0.8rem;
  font-weight: 100;
  text-align: end;
  margin-right: 30px;
  margin-bottom: 1rem;
}
.container-activities {
  height: 220px;
  overflow-y: auto;
  overflow-x: hidden;

  .items-activities {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 5px 10px 25px;
    margin: 5px 0;
    box-shadow: 5px 0 5px 0px #22273b, 5px 0px 5px 0px var(--color-secondary-dark);
    transition: all 0.3s linear;
    opacity: 0;
    p {
      width: calc(100% - 60px);
      margin: 0;
      line-height: 15px;
      font-size: 0.7rem;
      font-family: "lato-light";
      margin-left: 10px;
      color: var(--color-tertiary-light-shade);
    }
    .time-text {
      width: 80px;
      color: var(--color-tertiary-light-shade);
    }
    &:hover {
      background-color: var(--color-secondary-menium);
      cursor: pointer;
    }
  }
}

.profile-user {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 2rem;
  svg {
    fill: var(--color-contrast);
    width: 50px;
    height: 50px;
  }
  h3 {
    font-size: 1rem;
    font-weight: 100;
    margin: 5px 0 0 0;
  }
}

.button-sider {
  position: absolute;
  bottom: 15px;
  justify-content: center;
  button {
    background: transparent;
    border-radius: 5px;
    border: var(--border-btn) solid var(--color-contrast);
    margin-right: 10px;
    font-size: 0.9rem;
    padding: 5px 10px 5px 10px;
    color: var(--color-contrast);
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    transition: all 0.2s ease-in;
    position: relative;
    overflow: hidden;
    span {
      line-height: 15px;
      font-family: "lato-light";
    }
    svg {
      fill: var(--color-contrast);
      width: 15px;
      height: 15px;
      margin-left: 5px;
    }
    &:hover {
      color: inherit !important;
      border: var(--border-btn) solid var(--color-contrast) !important;
      margin-right: 10px !important;
      background-color: var(--color-secondary-dark);
    }
  }
}
.button-colpase {
  display: none;
  position: fixed;
  right: 15px;
  top: 15px;
  background-color: transparent;
  border: none;
  z-index: var(--z-index-level-1);
  svg {
    fill: var(--color-secondary-dark) !important;
    width: 30px;
    height: 30px;
  }
}

.colapse-side-calendar {
  width: 0px;

  header {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
  }
  .profile-user {
    display: none;
  }
  .title-activities {
    display: none;
  }

  .container-activities {
    display: none;
  }

  .button-sider {
    flex-direction: column;
    button {
      margin-bottom: 5px;
      border: none;
      span {
        display: none;
      }
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}
.button-colpase-show {
  display: block !important;
}

.button-colpase-close {
  visibility: hidden;
  position: absolute;
  left: 10px;
}
@media (max-width: 1024px) {
  .button-colpase-close {
    visibility: visible;
  }
}

@media (max-height: 700px) {
  .content-side-calendar {
    overflow-y: auto;
    .button-sider {
      position: relative;
      bottom: -20px;
      margin-bottom: 3rem;
    }
  }
}

// --> Estilos buscador
.form_search {
  position: relative;

  &__input {
    border-radius: 7px;
    border: none;
    padding: 0 40px 0 10px;
    outline: none;
    height: 30px;
    margin-top: 5px;
    opacity: 0;
    transform: scale(0, 0.5);
    transform-origin: right;
    width: 30px;
    font-family: "lato-light";
    background-color: transparent;

    ::placeholder {
      color: var(--color-tertiary-menium) !important;
    }
    // transition: opacity .2s linear, transform .2s ease-in;

    &--show {
      animation-name: showInput !important;
      animation-iteration-count: 1 !important;
      animation-timing-function: linear !important;
      animation-duration: 0.2s !important;
      animation-fill-mode: forwards !important;
      width: 200px;
      color: var(--color-contrast) !important;
      background-color: transparent;
      border: 1px solid var(--color-secondary-menium) !important;
      padding-right: 35px;
      @keyframes showInput {
        0% {
          opacity: 0.1;
          transform: scale(0, 0.5);
        }
        70% {
          opacity: 0.3;
          transform: scale(0.5, 0.7);
        }
        100% {
          opacity: 1;
          transform: scale(1, 1);
        }
      }
    }

    &--hidden {
      opacity: 1;
      transform: scale(1, 1) !important;
      animation-name: hiddenInput !important;
      animation-iteration-count: 1 !important;
      animation-timing-function: linear !important;
      animation-duration: 0.2s !important;
      animation-fill-mode: forwards !important;
      @keyframes hiddenInput {
        0% {
          opacity: 1;
          transform: scale(1, 1);
        }
        50% {
          opacity: 0.1;
          transform: scale(0.3, 0.8);
        }
        80% {
          opacity: 0;
          transform: scale(0.3, 0.8);
        }
        100% {
          opacity: 0;
          transform: scale(0, 0.5);
        }
      }
    }
  }

  &__button {
    position: absolute;
    right: 5px;
    top: 5px;
    background-color: transparent;
    border: none;
    color: var(--color-contrast);
    outline: none;
    cursor: pointer;

    .material-icons {
      font-size: 35px;
    }

    &--blue {
      .material-icons {
        font-size: 30px;
      }
    }

    &--white {
      color: white;
    }
  }
}

.not-data {
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  color: var(--color-tertiary-gray);
}
