@font-face {
  font-family: "lato";
  src: url("./Lato/Lato-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "lato-light";
  src: url("./Lato/Lato-Regular.ttf") format("truetype");

  font-display: swap;
}

@font-face {
  font-family: "lato-bold";
  src: url("./Lato/Lato-Bold.ttf") format("truetype");

  font-display: swap;
}

@font-face {
  font-family: "lato-italic";
  src: url("./Lato/Lato-Italic.ttf") format("truetype");
  font-display: swap;
}
