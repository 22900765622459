.carousel-indicators li {
    height: 13px !important;
    border-radius: 50% !important;
    width: 13px !important;
}
.carousel-indicators .active {
    background-color: var(--color-secondary-menium) !important;
}

.picsum-img-wrapper-initiative {
    .image-initiative {
        width: 100%;
        height: 350px;
        object-fit: var(--object-fit);
    }
}

.picsum-img-wrapper-initiative {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: inherit;
    background-color: var(--color-tertiary-gray);
    .container-descriptions {
        width: 35%;
    }
    .image-initiative {
        width: 65%;
    }
}

.carrucel-initiative {
    margin: 3rem 0 4rem 0;
    .carousel-indicators {
        bottom: -60px !important;
        li {
            background-color: var(--color-tertiary-dark-shade);
        }
    }
}

.container-descriptions {
    margin: 1rem 3rem;
    height: 100%;
    h3 {
        color: var(--color-secondary-menium);
        font-family: "lato-bold" !important;
        margin-bottom: 0;
        font-size: 1.2rem;
    }
    &_subtitle {
        color: var(--color-secondary-menium);
        font-style: italic;
        font-size: 1.1rem;
    }
    p {
        color: var(--color-secondary-light);
        line-height: 22px;
        margin-top: 1rem;
    }
}

@media (max-width: 1000px) {
    .picsum-img-wrapper-initiative {
        .image-initiative {
            //height: 310px;
        }
    }
}

@media (max-width: 900px) {
    .picsum-img-wrapper-initiative {
        .image-initiative {
            //height: 280px;
        }
    }
}

@media (max-width: 820px) {
    .picsum-img-wrapper-initiative {
        flex-direction: column-reverse;
        margin: 0 1rem;
        .image-initiative {
            width: 100%;
            //height: 240px;
            aspect-ratio: 10/4;
            height: auto;
        }
        .container-descriptions {
            width: 90%;
        }
        .container-descriptions-initiative {
            margin-top: 1rem;
        }

        .container-cards_button {
            margin-bottom: 0.5rem;
        }
    }

    .container-descriptions {
        h3 {
            font-size: 1.2rem;
        }
        &_subtitle {
            font-size: 1rem;
        }
    }
}
