.u-flex-center {
    display: flex;
    justify-content: center !important;
    align-items: center !important;
}

.u-flex-start-center {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.u-flex-end-center {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.u-align-center {
    text-align: center !important;
}

.u-cursor-pointer {
    cursor: pointer;
}

.u-cursor-none {
    cursor: none;
}

.u-cursor-auto {
    cursor: auto;
}

.u-margin-0 {
    margin: 0;
}

.u-ml-20 {
    margin-left: 20px;
}

.u-margin-top-05 {
    margin-top: 0.5rem !important;
}

.u-margin-bottom-05 {
    margin-top: 0.5rem;
}
.u-margin-top-0 {
    margin-top: 0rem;
}

.u-margin-top-1 {
    margin-top: 1rem !important;
}

.u-margin-top-1-5 {
    margin-top: 1.5rem !important;
}

.u-margin-top-2 {
    margin-top: 2rem;
}

.u-margin-top-3 {
    margin-top: 3rem;
}

.u-margin-top-4 {
    margin-top: 4rem;
}

.u-margin-top-5 {
    margin-top: 5rem;
}

.u-margin-bottom-1 {
    margin-top: 1rem;
}

.u-margin-bottom-1r {
    margin-bottom: 1rem;
}
.u-margin-bottom-2r {
    margin-bottom: 2rem !important;
}

.u-margin-bottom-2 {
    margin-top: 2rem;
}

.u-margin-bottom-3 {
    margin-top: 3rem;
}

.u-margin-right-20x {
    margin-right: 20px;
}

.u-margin-bottom-left {
    margin: 4rem 1rem 2rem 2rem;
}
.u-ml-1r {
    margin-left: 1rem;
}

.u-width-auto {
    width: auto;
}

.u-height-100 {
    height: 100%;
}

.u-width-100 {
    width: 100%;
}

.u-text-start {
    text-align: start !important;
}

.u-text-center {
    text-align: center !important;
}

.u-text-justify {
    text-align: justify !important;
}

.u-p-05 {
    padding: 0.5rem;
}

.u-flex-column {
    flex-direction: column;
}

.u-m-lr-1 {
    margin: 0 1rem;
}

.u-margin-1 {
    margin: 1rem;
}

.u-ml-5 {
    margin-left: 5px;
}

.u-mr-5 {
    margin-right: 5px;
}

.u-mr-10 {
    margin-right: 10px;
}

.u-width-50 {
    width: 50%;
}

.u-flex {
    display: flex;
}

.u-m-l-5 {
    margin-left: 5px;
}

.u-mtl-05 {
    margin: 0 1rem;
}

.u-margin-top-6 {
    margin-top: 6rem;
}

.u-mb-m3 {
    margin-bottom: -3rem;
}

.justify-center {
    justify-content: center;
}

.text-two-line {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    overflow: hidden;
}

.text-three-line {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    overflow: hidden;
}

.text-five-line {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    line-clamp: 5;
    overflow: hidden;
}

.u-overflow-y-auto {
    overflow-y: auto;
}
