.container-memories {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 4rem 6rem;
    &_image {
        width: 300px;
        height: 360px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 2rem;
        img {
            width: 270px;
            height: 360px;
            aspect-ratio: 5/7;
            object-fit: var(--object-fit);
            box-shadow: 0px 0px 5px -2px #a3a3a2;
            border-radius: var(--radius-img);
        }
    }

    .container-description {
        width: 50%;
        h3 {
            color: var(--color-secondary-menium);
            font-family: "lato-bold" !important;
            font-size: 1.5rem;
            margin-bottom: 2rem;
        }
        p {
            color: var(--color-secondary-light);
            line-height: 22px;
        }
        .container-cards_button {
            margin-top: 2rem;
        }
    }
}

@media (max-width: 780px) {
    .container-memories {
        margin: 4rem 1rem;
        flex-direction: column;
        &_image {
            padding-right: 0;
        }
        .container-description {
            width: 100%;
            margin-top: 1rem;
        }
    }
}

//multi cards
.container-multi-card {
    .content-card {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 1rem;
        &_image {
            width: 210px;
            height: 280px;
            aspect-ratio: 5/7;
            box-shadow: 0px 0px 5px -2px #a3a3a2;
            margin-top: 5px;
            border-radius: var(--radius-img);

            img {
                width: 100%;
                height: 100%;
                object-fit: var(--object-fit);
                border-radius: var(--radius-img);
            }
        }

        &_description {
            h3 {
                color: var(--color-secondary-menium);
                font-family: "lato-bold" !important;
                font-size: 1.1rem;
                margin-bottom: 1rem;
                margin-top: 1rem;
            }
        }
    }
}
