.carousel-indicators li {
    height: 13px !important;
    border-radius: 50% !important;
    width: 13px !important;
}
.carousel-indicators .active {
    background-color: var(--color-secondary-menium) !important;
}

.container-carrucel-card-event {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    margin: 2.5rem 2.5rem;
    .container-descriptions-event {
        width: calc(100% - 300px);
    }

    .container-descriptions-event-img {
        height: 280px;
        aspect-ratio: 6/6;
        img {
            width: 100%;
            height: 100%;
            object-fit: var(--object-fit);
            border-radius: var(--radius-img);
        }
    }
}

.carrucel-event {
    margin: 3rem 0 4rem 0;
    .carousel-indicators {
        bottom: -60px !important;
        li {
            background-color: var(--color-tertiary-dark-shade);
        }
    }
}

.container-descriptions-event {
    margin: 0 2rem 1rem 0rem;
    height: 100%;
    &_title {
        color: var(--color-secondary-menium);
        font-family: "lato-bold" !important;
        margin-bottom: 0;
        font-size: 1.3rem;
    }
    &_subtitle {
        color: var(--color-secondary-menium);
        font-size: 1.1rem;
    }
    &_date {
        width: 100%;
        text-align: end;
        color: var(--color-secondary-menium);
        font-size: 1.1rem;
        margin: -15px 0 0 0;
    }

    &_description {
        color: var(--color-secondary-light);
        line-height: 22px;
        margin-top: 1rem;
    }
}

@media (max-width: 1200px) {
    .container-carrucel-card-event {
        .container-descriptions-event {
            margin: 0 2rem 0 0rem;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
        }
    }
}

@media (max-width: 800px) {
    .container-carrucel-card-event {
        height: auto;
        flex-direction: column-reverse;
        margin: 0 1rem;
        .container-descriptions-event-img {
            width: 100%;
            //height: 240px;
        }
        .container-descriptions-event {
            width: 100%;
            margin-top: 1.5rem;
            margin: 1rem 0 !important;
            &_title {
                font-size: 1.3rem;
            }
            &_subtitle {
                font-size: 1rem;
            }
        }

        .container-cards_button {
            margin-bottom: 0.5rem;
        }
    }
}
