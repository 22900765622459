$w-sidebar: 50px;
$w-sidebar-lg: 180px;
.content-project-description {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 1rem;
    box-shadow: 0px 1px 5px -2px #a3a3a2a1;
    padding: 1rem;
    &_description {
        width: calc(100% - 50px);
        padding: 1rem;

        h3 {
            color: var(--color-secondary-dark-shade);
            font-size: 1.2rem;
        }
        p {
            color: var(--color-secondary-menium);
        }
    }
}
.inbox-sidebar {
    width: $w-sidebar;
    margin-right: 15px;

    ul {
        padding: 0 !important;
    }
    li {
        list-style: none;
        button {
            padding: 10px 0;
            margin-bottom: 15px;
            width: 100%;
            height: 100%;
            border: none;
            font-size: 0.9rem;
            font-weight: 600;
            background-color: var(--color-secondary-light-shade);
            color: var(--color-contrast);
            cursor: pointer;
            transition: background-color 0.3s cubic-bezier(0.41, 0.34, 0.75, 0.92);

            &:focus {
                outline: none !important;
            }
            &.active-btn,
            &:hover {
                background-color: var(--color-primary-dark-shade);
            }
        }
    }
}

.content-title {
    color: var(--color-secondary-menium);
    font-size: 1.2rem;
    &--golden {
        color: var(--color-primary-dark) !important;
        font-size: 1.3rem;
        font-weight: 600;
    }
}

.content-subtitle {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 1rem;
    font-size: 1.2rem;
    color: var(--color-secondary-menium);
}
