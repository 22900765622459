.modal_confirmation {
    font-size: 1.1rem;
    text-align: center;
    color: var(--color-secondary-menium);
    margin-top: 1.5rem;
}

@media (max-width: 315px) {
    .buttons-confirmation {
        flex-direction: column !important;
    }
}

.modal-only-confirmation {
    .modal-content {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 125%;
        pointer-events: auto;
        background-color: #f7f7f8;
        background-clip: padding-box;
        outline: 0;
        height: 120px;
        top: -15px;
        border: none !important;
        min-height: 90px !important;
    }
}
.modal-content {
    min-height: 90px !important;
}
