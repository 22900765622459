.carousel-indicators li {
    height: 13px !important;
    border-radius: 50% !important;
    width: 13px !important;
}
.carousel-indicators .active {
    background-color: var(--color-secondary-menium) !important;
}

.picsum-img-wrapper-about-us {
    .image {
        width: 100%;
        //height: 280px;
        aspect-ratio: 10/4.5;
        object-fit: var(--object-fit);
        max-height: 297px;
    }
}

.picsum-img-wrapper-about-us {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row-reverse;
    margin: 0 65px 0 65px;
    .container-descriptions {
        width: 50%;
    }

    .image {
        width: 50%;
        object-fit: var(--object-fit);
        border-radius: var(--radius-img);
    }
}

.carrucel-about-us {
    margin: 3rem 0 4rem 0;
    .carousel-indicators {
        bottom: -60px !important;
        li {
            background-color: var(--color-tertiary-dark-shade);
        }
    }
}

.container-descriptions {
    margin: 0 0 1rem 2rem;
    height: 100%;
    h3 {
        color: var(--color-secondary-menium);
        font-family: "lato-bold" !important;
        margin-bottom: 1rem;
        font-size: 1.5rem;
    }
    p {
        color: var(--color-secondary-light);
        line-height: 22px;
    }
}

@media (max-width: 800px) {
    .picsum-img-wrapper-about-us {
        height: auto;
        flex-direction: column-reverse;
        margin: 0 1rem;
        .image {
            width: 100%;
        }
        .container-descriptions {
            width: 100%;
            margin-top: 1.5rem;
            margin: 1rem 0 !important;
            h3 {
                font-size: 1.3rem;
            }
            span {
                font-size: 1rem;
            }
        }

        .container-cards_button {
            margin-bottom: 0.5rem;
        }
    }
}
